import Vue from 'vue'
import App from './App.vue'
import axios from 'axios'
import currencyFilter from './Filters/currency'
import router from './router'
import apiRoutes from './prototypes/api';
import API_TOKEN from './utils/api'
import Pusher from 'pusher-js'

const pusher = new Pusher('47c21826ad4597a7049a', {
  cluster: 'us2',
})

Vue.config.productionTip = false


Vue.filter('currency', currencyFilter)
Vue.use(apiRoutes)




const axiosInstance = axios.create({
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "multipart/form-data",
    "Token" : API_TOKEN
  }
});

Vue.prototype.$http = axiosInstance
Vue.prototype.$pusher = pusher

Vue.config.debug = true

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
