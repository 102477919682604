// const BASE_URL_PRINT = 'http://impresion.test/print.php'
// const BASE_URL = 'http://impresion.test/proxy.php'
// const BASE_URL_LABEL = 'http://impresion.test/label.php'

const BASE_URL_PRINT = 'https://impresion.solochicasboutique.com/print.php'
const BASE_URL = 'https://impresion.solochicasboutique.com/proxy.php'
const BASE_URL_LABEL = 'https://impresion.solochicasboutique.com/label.php'


const BASE_URL_PROXY = 'https://pos.solochicasboutique.com/api/3/'
const ENDPOINT_FACTURAS = 'facturaclientes'
const ENDPOINT_FACTURAS_LINEAS= 'lineafacturaclientes'
const PROXY_ENDPOINT = `${BASE_URL}?csurl=`

export default {
    install(Vue) {
        Vue.prototype.$BASE_URL = BASE_URL
        Vue.prototype.$ENDPOINT_FACTURAS = ENDPOINT_FACTURAS
        Vue.prototype.$ENDPOINT_FACTURAS_LINEAS = ENDPOINT_FACTURAS_LINEAS
        Vue.prototype.$BASE_URL_PROXY = BASE_URL_PROXY
        Vue.prototype.$PROXY_ENDPOINT = PROXY_ENDPOINT
        Vue.prototype.$BASE_URL_PRINT = BASE_URL_PRINT
        Vue.prototype.$BASE_URL_LABEL = BASE_URL_LABEL
        Vue.prototype.$ENDPOINT_PRODUCTOS = `${BASE_URL_PROXY}productos`
    },
  };