<template>
  <div class="home">
    <Options></Options>
  </div>
</template>

<script>
import Options from '@/components/Options.vue'

export default {
  name: 'Home',
  components: {
    Options
  }
}
</script>
