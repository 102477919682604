<template>
    <div class="options">

        <button @click="goToImprimirLabel()">IMPRIMIR ETIQUETA DE PRECIOS</button>
        <button @click="goToImprimirLabelSmall()">IMPRIMIR ETIQUETA DE PRECIOS SMALL</button>
        <button @click="goToImprimirDescuento()">IMPRIMIR DESCUENTO DE PRECIOS</button>
        
    </div>    

</template>
<script>
export default {
    name: 'Options',
    methods: {
        goToImprimirLabel () {
            this.$router.push('/label')
        },

        goToImprimirLabelSmall () {
            this.$router.push('/labelsmall')
        },

        goToImprimirDescuento () {
            this.$router.push('/discount')
        }
    }
}
</script>

<style>
.options {
    margin-top: 5%;
    text-align: center;
}
.options > button {
    font-size: 40px;
}
</style>