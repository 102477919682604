<template>
    <div>
        <div v-show="!showImpresion">
            <div>Consultar Codigo: <input type="text" v-model="codigo" @keyup.enter="ConsultarCodigo">
                <button @click="ConsultarCodigo">Buscar</button>
            </div>
            <div v-show="showData">
                <div>
                    COLOR: <input type="text" name="color" id="color" v-model="color">
                </div>
                <div>
                    PRECIO: <input type="text" name="precio" id="precio" v-model="precio">
                </div>
                <div>
                    CANTIDAD: <input type="text" name="cantidad" id="cantidad" v-model="cantidad">
                </div>
                <div>
                CODIGO: <input type="text" name="codigo" id="codigo" v-model="codigo">
                </div>
                <div>
                    <input type="checkbox" name="talla[]" v-model="tallas" value='XS'> XS
                    <input type="checkbox" name="talla[]" v-model="tallas" value='S' > S
                    <input type="checkbox" name="talla[]" v-model="tallas" value='M' > M
                    <input type="checkbox" name="talla[]" v-model="tallas" value='L' > L
                    <input type="checkbox" name="talla[]" v-model="tallas" value='XL' > XL
                    <input type="checkbox" name="talla[]" v-model="tallas" value='UNICA' > UNICA
                    <input type="checkbox" name="talla[]" v-model="tallas" value='NO'> NO
                </div>


                <div>
                    <button @click="Mostar">Mostrar Impresion</button>
                </div>

            </div>

        </div>

        <div class="label-div" v-show="showImpresion">
            <div class="printButtons">
                <button @click="regresar">Regresar</button>
                <button @click="mostrarCodigos">Mostrar Codigos de Barra</button>
            </div>
            <div v-for="(talla, index ) in tallas" :key="index">
                <div v-for="index in parseInt(cantidad)" :key="index">
                    <span class="label-talla" v-show="talla != 'NO'">TALLA: {{ talla  }}<br></span>
                    <span>{{ color }} <br></span>
                    <span  class="label-precio" >PRECIO: ₡ {{ precio | currency }}<br></span>
                    <svg class="barcode"
                    jsbarcode-format="CODE128"
                    :jsbarcode-value="codigo"
                    jsbarcode-textmargin="0"
                    jsbarcode-fontoptions="bold"
                    jsbarcode-height="40"
                    >
                    </svg>
                    <br>
                    <br>
                </div>
            </div>
            

            <div class="printButtons">
                <button @click="mostrarCodigos">Mostrar Codigos de Barra</button>
                <button onclick="window.print()">Imprimir</button>
                <button @click="reiniciar">Nueva Etiqueta</button>
            </div>
        </div>
    </div>
</template>

<script>

import JsBarcode from 'jsbarcode'

export default {
    name: 'Etiqueta',
    data () {
        return {
            codigo: '',
            barcode: '',
            showData: false,
            cantidad: 1,
            tallas: [],
            precio: '',
            producto: {},
            showImpresion: false,
            color: '',
            showImpresionMobile: false
        }
    },

    mounted() {
            console.log('start channel')
      
            const channel = this.$pusher.subscribe('impresion-etiqueta-channel');

            channel.bind('imprimir-etiqueta', (data) => {
                console.log(data);
                this.tallas = []
                this.showImpresionMobile = true
                this.tallas.push(data.talla);
                this.codigo = data.codigo
                this.ConsultarCodigo()
            })

            channel.bind('pusher:subscription_error', function() {
                console.log("Oh nooooos!")
            })
    },

    watch: {
        barcode() {
                setTimeout(() => {
                this.mostrarCodigos()
                setTimeout(() => {
                    window.print()
                }, 400);
            }, 300);
        }
    },

    methods: {


        ConsultarCodigo () {

            //this.$http.post(``)

            this.$http.get(`${this.$PROXY_ENDPOINT}${this.$ENDPOINT_PRODUCTOS}?filter[referencia]=${this.codigo}`)
            .then(response => {
                this.showData = true
                console.log(response.data)
                if (response.data.length > 0 ) {
                    this.producto = response.data[0]
                    this.precio = this.producto.precio
                    this.color = this.producto.descripcion
                    if (this.showImpresionMobile) {
                        this.showImpresion = true
                        this.showImpresionMobile = false
                        this.barcode = `${this.codigo}${Math.floor(Math.random() * 10) + 1}`
                    }
                }
            })
        },

        Mostar() {
            this.showData=false
            this.showImpresion =true
        },

        regresar() {
            this.showData=true
            this.showImpresion = false
        },

        reiniciar() {
            this.showImpresion = false
        },

        mostrarCodigos() {
            JsBarcode('.barcode').init()
        }
     }
}
</script>

<style>

.label-precio {
font-size: 14px;
font-weight: 900;
}

.label-talla {
    font-size: 13px;
    font-weight: 900;
}

    .label-div {
    width: 178px;
    text-align: center;
    font-size: 11px;
    font-weight: 500;
}
  @media print {

  .printButtons {
    display: none;
  }

  img {
    width: 140px;
  }
}
</style>
